import FotoServico1 from '../../src/assets/img/servico-podar.png'
import FotoServico2 from '../../src/assets/img/servico-decoracao.png'
import FotoServico3 from '../../src/assets/img/servico-plantar.png'
import FotoServico4 from '../../src/assets/img/servico-grama.png'

const features = [
    { name: 'Adubação, plantio e cuidado de flores, árvores e arbustos', description: 'Transforme seu jardim! Adubação, plantio e cuidado profissional para flores, árvores e arbustos. Garantimos seu jardim florido e saudável. ' },
    { name: 'Corte e manutenção de grama', description: 'Corte e manutenção de grama feita por profissionais. Deixe seu gramado sempre verde e saudável, garantimos o melhor acabamento.' },
    { name: 'Controle de ervas daninhas, remoção de folhagem e resíduos vegetais', description: 'Remova as ervas daninhas e mantenha sua vegetação saudável. Oferecemos controle de ervas daninhas e remoção de folhagem para deixar seu jardim impecável. ' },
    { name: 'Poda de árvores e arbustos', description: 'Cuidado profissional para suas árvores e arbustos. Podas precisas e seguras para manter sua vegetação saudável e bonita.' },
    { name: 'Instalação de jardins verticais', description: 'Adicione vida e cor a sua parede! Oferecemos instalação profissional de jardins verticais, proporcionando um visual incrível e benefícios ambientais.' },
    { name: 'Instalação de hortas in-door', description: 'Tenha sua própria horta dentro de casa! Oferecemos instalação profissional para sua horta indoor, garantimos sucesso na colheita de vegetais e temperos frescos.' },
    { name: 'Instalação e manutenção de sistemas de rega', description: 'Instalação e sistema de rega de jardim feito por profissionais. Obtenha jardins saudáveis e verdes com a rega adequada.' },
    { name: 'Instalação de pátios, cercas, fontes e outros objetos de decoração e paisagismo', description: 'Tratamento profissional para seus pátios, cercas, fontes e outros elementos de jardim. Adicione estilo e funcionalidade ao seu espaço externo.' },
  ]
  
  export default function Servicos() {
    return (
      <div className="bg-golden-50">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-cuida-900 sm:text-4xl">Serviços de Jardinagem e Paisagismo</h2>
            <p className="mt-4 text-gray-500">
            Transforme seu espaço externo com nossos serviços de jardinagem e paisagismo. Oferecemos tudo o que você precisa e temos também itens de decoração para deixar seu jardim ainda mais bonito. Cuidamos de tudo pra você.
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-cuida-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
              src={FotoServico1}
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={FotoServico2}
              alt="Top down view of walnut card tray with embedded magnets and card groove."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={FotoServico3}
              alt="Side of walnut card tray with card groove and recessed card area."
              className="rounded-lg bg-gray-100"
            />
            <img
              src={FotoServico4}
              alt="Walnut card tray filled with cards and card angled in dedicated groove."
              className="rounded-lg bg-gray-100"
            />
          </div>
        </div>
      </div>
    )
  }