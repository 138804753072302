import ProdutoPlantaSementes from '../assets/img/produtos/produto-plantas-sementes.png';
import ProdutoAduboFertilizante from '../assets/img/produtos/produto-terra-adubo.png';
import ProdutoGramaTerra from '../assets/img/produtos/produto-grama.png';
import ProdutoPedraRocha from '../assets/img/produtos/produto-pedras-rochas.png';
import ProdutoVasos from '../assets/img/produtos/produto-vasos-recipientes.png';
import ProdutoHerbicida from '../assets/img/produtos/produto-inseticida-herbicida.png';
import ProdutoPas from '../assets/img/produtos/produto-pas.png';
import ProdutoTesoura from '../assets/img/produtos/produto-tesoura-podoes.png';
import ProdutoSerras from '../assets/img/produtos/produto-serras.png';
import ProdutoMoveis from '../assets/img/produtos/produto-decoracao.png';
import ProdutoPlantasFossilizadas from '../assets/img/produtos/produto-plantas-fossilizadas.png';
import ProdutoFontes from '../assets/img/produtos/produto-fontes-lareiras.png';
import ProdutoQuadros from '../assets/img/produtos/produto-quadros.png';
import ProdutoJardins from '../assets/img/produtos/produto-jardim-vertical.png';
import ProdutoEstufas from '../assets/img/produtos/produto-estufas.png';
import ProdutoAromatizantes from '../assets/img/produtos/produto-perfume-aromatizante.png';
import ProdutoIncensos from '../assets/img/produtos/produto-incenso.png';
import ProdutoMangueira from '../assets/img/produtos/produto-mangueira.png';
import ProdutoRegadores from '../assets/img/produtos/produto-regadores.png';


const categories = [
  {
    name: 'Plantas e Sementes',
    href: '#',
    imageSrc: ProdutoPlantaSementes,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Adubos e Fertilizantes',
    href: '#',
    imageSrc: ProdutoAduboFertilizante,
    imageAlt: 'Brown leather key ring with brass metal loops and rivets on wood table.',
    description: 'Keep your phone, keys, and wallet together, so you can lose everything at once.',
  },
  {
    name: 'Grama e Terra',
    href: '#',
    imageSrc: ProdutoGramaTerra,
    imageAlt: 'Brown leather key ring with brass metal loops and rivets on wood table.',
    description: 'Keep your phone, keys, and wallet together, so you can lose everything at once.',
  },
  {
    name: 'Pedras e Rochas',
    href: '#',
    imageSrc: ProdutoPedraRocha,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Vasos e Recipientes',
    href: '#',
    imageSrc: ProdutoVasos,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    
    name: 'Herbicidas, Fungicidas, Inseticidas',
    href: '#',
    imageSrc: ProdutoHerbicida,
    imageAlt: 'Natural leather mouse pad on white desk next to porcelain mug and keyboard.',
    description: 'The rest of the house will still be a mess, but your desk will look great.',
  },
  {
    name: 'Mangueiras e Acessórios',
    href: '#',
    imageSrc: ProdutoMangueira,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Regadores e Aspersores',
    href: '#',
    imageSrc: ProdutoRegadores,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Pás e Enxadas',
    href: '#',
    imageSrc: ProdutoPas,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Tesouras e Podões',
    href: '#',
    imageSrc: ProdutoTesoura,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Serras',
    href: '#',
    imageSrc: ProdutoSerras,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Plantas Fossilizadas',
    href: '#',
    imageSrc: ProdutoPlantasFossilizadas,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Fontes e Lareiras',
    href: '#',
    imageSrc: ProdutoFontes,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Móveis, Esculturas e Decoração',
    href: '#',
    imageSrc: ProdutoMoveis,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Quadros',
    href: '#',
    imageSrc: ProdutoQuadros,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Jardins Verticais',
    href: '#',
    imageSrc: ProdutoJardins,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Estufas e In-door Grow',
    href: '#',
    imageSrc: ProdutoEstufas,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Aromatizantes e Cheirinhos',
    href: '#',
    imageSrc: ProdutoAromatizantes,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
  {
    name: 'Incensos Naturais',
    href: '#',
    imageSrc: ProdutoIncensos,
    imageAlt: 'Person placing task list card into walnut card holder next to felt carrying case on leather desk pad.',
    description: 'Be more productive than enterprise project managers with a single piece of paper.',
  },
]

export default function CategoryList() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-xl py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-cuida-900 sr-only">Todos os produtos</h2>

        <div className="mt-10 space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16 lg:space-y-0">
          {categories.map((category) => (
            <div key={category.name} href={category.href} className="group block">
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg lg:aspect-w-5 lg:aspect-h-6"
              >
                <img
                  src={category.imageSrc}
                  alt={category.imageAlt}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <h3 className="mt-4 text-base font-semibold text-cuida-900">{category.name}</h3>
              {/* <p className="mt-2 text-sm text-gray-500">{category.description}</p> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
