export default function RegiaoAtendimento() {
    return (
      <div className="bg-cuida-900">
        <div className="mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-golden-200">Área de atendimento - Região dos Lagos</h2>
          <p className="mt-6 max-w-3xl text-lg text-golden-50">
            Serviços de Jardinagem e Paisagismo em toda a Região dos Lagos, RJ. 
            <br/>Horário de atendimento 09:00 até as 19:00h.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <h3 className="text-lg font-medium text-golden-200">Cabo Frio</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços residenciais e comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">São Pedro da Aldeia</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços residenciais e comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">Armação de Búzios</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços Residenciais e Comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">Arraial do Cabo</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços Residenciais e Comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">Iguaba Grande</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços Residenciais e Comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">Saquarema</h3>
              <p className="mt-2 text-base text-golden-50">
                <span className="block">Venda de Produtos</span>
                <span className="block">Serviços apenas para estabelecimentos comerciais</span>
              </p>
            </div>
            <div>
              <h3 className="text-lg font-medium text-golden-200">Araruama</h3>
              <p className="mt-2 text-base text-golden-50">
              <span className="block">Venda de produtos</span>
              <span className="block">Serviços apenas para estabelecimentos comerciais</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  