import HeroCatalog from '../layout/HeroCatalog'
import RegiaoAtendimento from '../layout/RegiaoAtendimento'
import Incentivos from '../layout/Incentivos'
import Footer from '../layout/Footer'
import CategoryList from '../layout/CategoryList'
import Navigation from '../layout/Navigation'
import Stats from '../layout/Stats'
import Contato from '../layout/Contato'
import CatalogCTA from '../layout/CatalogCTA'


export default function Catalogo() {
  return (
    <>
      <Navigation/>
      <HeroCatalog/>
      <CategoryList/>
      <CatalogCTA/>
      <RegiaoAtendimento/>
      <Incentivos/>
      <Stats/>
      <Contato/>
      <Footer/>
    </>
  )
}