export default function HeroCatalog() {
    return (
      <div className="bg-golden-50">
        <div className="mx-auto max-w-7xl py-8 px-6 sm:py-16 lg:px-8">
          <div className="text-center">
            <p className="text-lg font-semibold text-cuida-600">Jardinagem e Paisagismo</p>
            <h1 className="mt-1 text-4xl font-bold tracking-tight text-cuida-900 sm:text-5xl lg:text-6xl">
              Catálogo de Produtos
            </h1>
            <p className="mx-auto mt-5 max-w-xxl text-xl text-gray-500">
            Confira nosso catálogo produtos de jardinagem e paisagismo, como plantas, sementes, ferramentas, adubos, itens de decoração e outros materiais para criar e cuidar de jardins e paisagens.
            </p>
          </div>
        </div>
      </div>
    )
  }
  