export default function Stats() {
    return (
      <div className="bg-cuida-800 pt-12 sm:pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-golden-200 sm:text-4xl">
              Jardinagem e Paisagismo de confiança na Região dos Lagos, RJ
            </h2>
          </div>
        </div>
        <div className="mt-10 bg-golden-50 pb-12 sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-cuida-800" />
            <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-4xl">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Clientes</dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-cuida-600">100+</dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Equipe</dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-cuida-600">10</dd>
                  </div>
                  <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">Projetos Sociais</dt>
                    <dd className="order-1 text-5xl font-bold tracking-tight text-cuida-600">1</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  