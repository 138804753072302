import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Catalogo from "./pages/Catalogo"


export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/produtos" element={<Catalogo />} />
    </Routes>
  )
}