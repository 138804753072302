const faqs = [
  {
    question: "A primeira visita serve pra que?",
    answer:
      "Vamos conhecer o local e ter uma idéia de esforço necessário para poder te fazer uma proposta. Nossos planos começam em R$150 no mês ou R$300 por serviço avulso.",
  },
  {
    question: "O que vocês levam em consideração para definir o preço?",
    answer:
      "Os 3 principais itens que levamos em conta são: Tamanho e complexidade do jardim, os serviços necessários e o grau de risco. Para jardins residenciais, geralmente o tamanho é o maior fator.",
  },
  {
    question: "O material está incluso no valor cobrado?",
    answer:
      "O valor é apenas pela mão de obra, se houver a necessidade de plantio de terra ou aplicação de qualquer tipo de material, você pode comprar de onde preferir. Se tivermos o produto em nosso catálogo, oferecemos com desconto para facilitar a realização do serviço.",
  },
  {
    question: "Com que frequência vocês recomendam cuidar do jardim?",
    answer:
      "Na maioria das vezes, com uma sessão por mês o seu jardim fica bem cuidado. Dependendo das condições de luz e das flores ou vegetações presentes, podemos indicar algum tipo de tratamento específico",
  },

  {
    question: "Como funciona a cobrança?",
    answer: "Você pode pagar com PIX todo mês ou com cartão de crédito. ",
  },
  {
    question:
      "Quais os aromas do brinde mensal que eu ganho ao assinar um pacote?",
    answer:
      "Você pode escolher os aromas de Capim Limão, Manga Rosa, Pimenta Rosa, Alecrim ou Bambu",
  },
  {
    question: "Vocês trabalham com flores e arranjos fossilizados?",
    answer:
      "Temos parceiros do Rio que possuem um grande catálogo de plantas fossilizadas e que também produzem sob demanda. Podemos encomendar e instalar pra você.",
  },
  {
    question: "Vocês fazem manutenção em jardins e espaços comerciais?",
    answer:
      "Sim. Só entrar em contato que retornamos o mais breve possível. Geralmente respondemos dentro de algumas horas.",
  },
  // More questions...
];

export default function FAQ() {
  return (
    <div className="bg-golden-50">
      <div className="mx-auto max-w-7xl py-16 px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-cuida-900">
              Perguntas Frequentes
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Tem alguma outra pergunta?
              <a
                href="https://wa.me/5521977468010"
                className="block font-medium text-golden-600 hover:text-golden-500"
              >
                Dá um oi no Whatsapp! (21) 97746-8010
              </a>
            </p>
          </div>
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <dl className="space-y-12">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-lg font-medium leading-6 text-cuida-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
