import {
  ArrowPathIcon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Logo from '../../src/assets/img/logo-cuidajardim-1.svg'
import DestaqueHome from '../../src/assets/img/home-jardim-cuidado.jpg';

const features = [
  {
    name: "Analytics",
    href: "#",
    description:
      "Get a better understanding of where your traffic is coming from.",
    icon: ChartBarIcon,
  },
  {
    name: "Engagement",
    href: "#",
    description: "Speak directly to your customers in a more meaningful way.",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    href: "#",
    description: "Your customers' data will be safe and secure.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Integrations",
    href: "#",
    description: "Connect with third-party tools that you're already using.",
    icon: Squares2X2Icon,
  },
  {
    name: "Automations",
    href: "#",
    description:
      "Build strategic funnels that will drive your customers to convert",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];
const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: LifebuoyIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkSquareIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
];
const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeroHome() {
  return (
    <div className="relative bg-golden-50">
      <main className="flex flex-col lg:relative">
        <div className="order-last lg:order-first mx-auto w-full max-w-7xl py-20 text-center lg:py-20 xl:py-36 lg:text-left">
          <div className="flex flex-col px-6 sm:px-8 lg:w-1/2 xl:pr-16">
            <div className="flex justify-center order-4 mt-20 lg:order-1 lg:justify-start lg:mt-0 lg:mb-10  items-center w-100">
              <img className="block max-w-sm w-auto text-center" src={Logo} alt="Cuida Jardim" />
            </div>
            <h1 className="flex flex-col text-4xl order-1 font-bold tracking-tight text-cuida-900 sm:text-5xl md:text-6xl lg:order-1 lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Cuidamos do</span>{" "}
              <span className="block text-cuida-600 xl:inline">seu Jardim</span>
            </h1>
            <p className="flex mx-auto order-2  mt-10 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl lg:order-3">
              Cortamos e aparamos sua grama grama, adubamos, plantamos flores e
              vegetais, podamos e deixamos tudo limpo. Tudo isso à partir de
              R$150,00 por mês.
            </p>
            <div className="order-3  mt-10 sm:flex sm:justify-center lg:order-4 lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="https://wa.me/5521977468010"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-golden-500 px-8 py-3 text-base font-medium text-golden-50 hover:bg-golden-700 md:py-4 md:px-10 md:text-lg"
                >
                  Agendar Visita (Grátis)
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link
                  to="/produtos#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-golden-100 px-8 py-3 text-base font-medium text-golden-600 hover:bg-golden-200 md:py-4 md:px-10 md:text-lg"
                >
                  Ver Produtos
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="order-first lg:order-last relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={DestaqueHome}
            alt=""
          />
        </div>
      </main>
    </div>
  );
}
