import { Link } from "react-router-dom";

export default function CatalogCTA() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-6 text-center lg:py-24 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-cuida-900 sm:text-4xl">
          <span className="block">Ficou interessado? Compre pelo Whatsapp</span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://wa.me/5521977468010"
              target="_blank"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cuida-600 px-5 py-3 text-base font-medium text-white hover:bg-cuida-700"
            >
              Chamar no Whatsapp (21) 97746-8010
            </a>
          </div>
          <div className="ml-3 inline-flex">
            <Link
              to="/"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cuida-100 px-5 py-3 text-base font-medium text-cuida-700 hover:bg-cuida-200"
            >
              Ver serviços
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
