import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/20/solid";
import Logo from '../../src/assets/img/logo-cuidajardim-cursive-golden-full.svg'
import { Link } from "react-router-dom"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const navClasses = {
    selected: "inline-flex items-center border-b-2 border-golden-300 px-1 pt-1 text-sm font-medium text-golden-100",
    notSelected: "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-golden-300 hover:border-golden-300 hover:text-golden-200",
  }

  const getNavClass = (id) => {
    const urlArr = window.location.href.split("/");
    const locationName = urlArr[urlArr.length-1];
    console.log('getNavClass1: location name', locationName);
    if (locationName === '') {
      console.log('getNavClass1: entrou no primeiro if')
      return 'home'      
    }    

    if (locationName === id) {
      console.log('getNavClass1: entrou no true do segundo if')
      return true 
    } else {
      console.log('getNavClass1: entrou no false do segundo if')
      return false
    }
}
  return (
    <Disclosure as="nav" className="bg-cuida-900 shadow">
      {({ open }) => (
        <>{}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-golden-200 hover:bg-cuida-700 hover:text-golden-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cuida-500">
                    <span className="sr-only">Abrir Menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <Link to='/'>
                    <img
                      className="block h-12 w-auto"
                      src={Logo}
                      alt="Cuida Jardim"
                    />
                  </Link>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-cuida-500 text-cuida-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a
                    id="home"
                    href="/"
                    className={ getNavClass('home') === 'home' ? navClasses.selected : navClasses.notSelected }
                  >
                    Nossos Serviços
                  </a>
                  <Link
                    id="produtos"
                    to="/produtos"
                    className={ getNavClass('produtos') === true ? navClasses.selected : navClasses.notSelected }
                  >
                    Catálogo de Produtos
                  </Link>
                </div>
              </div>
              {/* <div className="flex items-center">
                <div className="flex-shrink-0">
                  <a href="https://wa.me/5521977468010" target="_blank" rel="noreferrer">

                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md border border-transparent bg-cuida-600 px-4 py-2 text-sm font-medium text-golden-200 shadow-sm hover:bg-cuida-700 focus:outline-none focus:ring-2 focus:ring-cuida-500 focus:ring-offset-2"
                    >
                    <PhoneIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                      />
                    <span>(21) 97746-8010</span>
                  </button>
                      </a>
                </div>
              </div> */}
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 pt-2 pb-3">
              {/* Current: "bg-cuida-50 border-cuida-500 text-cuida-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as="a"
                href="/"
                className="block border-l-4 border-cuida-500 bg-golden-100 py-2 pl-3 pr-4 text-base font-medium text-cuida-700 sm:pl-5 sm:pr-6"
              >
                Serviços
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/produtos"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-golden-200 hover:border-cuida-700 hover:bg-cuida-800 hover:text-golden-100 sm:pl-5 sm:pr-6"
              >
                Produtos
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
