import HeroHome from '../layout/HeroHome'
import PrecosPF from '../layout/PrecosPF'
import RegiaoAtendimento from '../layout/RegiaoAtendimento'
import Servicos from '../layout/Servicos'
import Stats from '../layout/Stats'
import TabelaPrecos from '../layout/TabelaPrecos'
import Testimonials from '../layout/Testimonials'
import Reviews from '../layout/Reviews'
import FAQ from '../layout/FAQ'
import Produtos from '../layout/Produtos'
import Incentivos from '../layout/Incentivos'
import Footer from '../layout/Footer'
import Contato from '../layout/Contato'
import Navigation from '../layout/Navigation'


export default function Home() {
  return (
    <>
      <Navigation/>
      <HeroHome/>
      <RegiaoAtendimento/>
      <Servicos/>
      <TabelaPrecos/>
      <FAQ/>
      <Produtos/>
      <Incentivos/>
      <Testimonials/>
      <Reviews/>
      <Stats/>
      <Contato/>
      <Footer/>
    </>
  )
}