import { HandThumbUpIcon, CheckBadgeIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
const incentives = [
    {
      name: 'Atendimento Personalizado',
      description: "Atendimento rápido e eficiente garantido! Nossa equipe está pronta para ajudar você.",
    },
    {
      name: 'Qualidade',
      description: "Garantimos qualidade em todos os aspectos! Utilizamos somente os melhores materiais e fornecedores para garantir a qualidade do seu jardim.",
    },
    {
      name: 'Garantia de Satisfação',
      description:
        "Nossos clientes são nossa melhor referência. Temos uma longa lista de clientes satisfeitos que continuam a confiar em nós para cuidar de seus jardins.",
    },
  ]

  const getIcon = (iconName) => {
switch (iconName) {
  case 'Atendimento Personalizado':
    return (<ChatBubbleLeftRightIcon className="-ml-1 mr-2 h-12 w-12 text-cuida-500" aria-hidden="true"/>)
  case 'Qualidade':
    return (<CheckBadgeIcon className="-ml-1 mr-2 h-12 w-12 text-cuida-500" aria-hidden="true"/>)
  case 'Garantia de Satisfação':
    return (<HandThumbUpIcon className="-ml-1 mr-2 h-12 w-12 text-cuida-500" aria-hidden="true"/>)
  default:
    break  
}
  }
  
  export default function Incentivos() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-32 lg:px-4">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
            <div className="max-w-3xl">
              <h2 className="text-4xl font-bold tracking-tight text-cuida-900">
                Nosso negócio só floresce com você.
              </h2>
              <p className="mt-4 text-gray-500">
              Nós entendemos que o seu tempo é precioso, e por isso, sempre damos prioridade em responder às suas perguntas o mais rápido possível. Somos totalmente transparentes em tudo o que fazemos e prometemos cuidar do seu jardim como se fosse nosso próprio. Com isso, temos a certeza de que você ficará completamente satisfeito com os nossos serviços!
              </p>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div key={incentive.name} className="sm:flex lg:block">
                  <div className="sm:flex-shrink-0">
                    {getIcon(incentive.name)}
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-sm font-medium text-cuida-900">{incentive.name}</h3>
                    <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  